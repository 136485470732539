<template>
  <div class="banner">
    <video class="video" src="../assets/video/banner.mp4" autoplay muted  loop></video>
    <div class="banner-font">
        <div class="font-english">SCIENCE MOVEMENT LEADER</div>
        <div class="font-chinese">科学运动引领者</div>
    </div>
  </div>
</template>

<script>
export default {
    created(){
        document.documentElement.scrollTop = 0
    }
}
</script>

<style lang="less" scoped>

.banner{
    width: 100%;
    .video{
        display: block;
        width: 100%;
    }
    .banner-font{
       position: absolute;
        top:50%;
        left:50%;
        color: #fff;
        transform: translate(-50%,-50%);
        text-align: center;
        .font-english{
            font-weight: 600;
            font-size: 0.3rem;
            animation:fadenum 4s 1;
            -webkit-animation:fadenum 4s 1;
    	    -moz-animation:fadenum 4s 1;

        }
        .font-chinese{
            margin-top: 0.12rem;
            font-size: 0.12rem;
             animation:fadenum 4s 1;
            -webkit-animation:fadenum 4s 1;
    	    -moz-animation:fadenum 4s 1;
        }
    }
}
@keyframes fadenum{
   0%{opacity: 0;}
   50%{opacity: 0.8;}
   100%{opacity: 1;}
}
</style>
